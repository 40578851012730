<template>
  <div>
    <Box
      :title="$t('465666e')"
      :sub-title="`（${$t('10a66bd')}！）`"
      class="detail-box"
    >
      <el-button
        v-if="canCancel"
        slot="side"
        class="btn-cancel"
        @click="doAction('cancel')"
      >{{ $t('3e5fd7d') }}</el-button>
      <DetailBox v-loading="loading" type="detail" :data="detail" />
      <div v-if="detail.flag" class="footer">
        <el-button
          v-if="detail.status === 'COMMUNICATED'"
          type="primary"
          class="btn"
          @click="doAction('processing')"
        >{{ $t('6bb96c5') }}</el-button>
        <el-button
          v-if="detail.status === 'IN_COOPERATION'"
          type="primary"
          class="btn"
          @click="doAction('completed')"
        >{{ $t('c3c076c') }}</el-button>
        <el-button
          v-if="detail.status === 'TO_BE_COMMUNICATED'"
          type="primary"
          class="btn"
          @click="doAction('communicated')"
        >{{ $t('92c8446') }}</el-button>
      </div>
    </Box>
  </div>
</template>

<script>
import Box from '@/components/Box'
import DetailBox from '../OrderItem'
import Api from '@/api/service'
import { MessageBox, Message } from 'element-ui'

export default {
  components: {
    Box,
    DetailBox,
  },
  data() {
    return {
      detail: {},
      loading: true,
    }
  },
  computed: {
    canCancel: function() {
      return (
        this.detail.status &&
        !~['COMPLETED', 'CANCEL'].indexOf(this.detail.status)
      )
    },
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.loading = true
      const id = this.$route.query.id
      Api.orderDetail(id)
        .then((res) => {
          this.detail = res
        })
        .catch(null)
        .then((res) => {
          this.loading = false
        })
    },
    doAction(type) {
      const id = this.$route.query.id
      let msg = '确定要执行该操作吗？'
      let request = null

      if (type === 'cancel') {
        msg = '确定要取消'
        request = Api.orderCancel
      } else if (type === 'processing') {
        msg = '确定要合作'
        request = Api.orderProcess
      } else if (type === 'completed') {
        msg = '确定已完成'
        request = Api.orderComplete
      } else if (type === 'communicated') {
        msg = '确定已沟通'
        request = Api.orderCommunicate
      }

      if (!request) {
        return
      }

      MessageBox.confirm(msg, '确认提示').then((res) => {
        request(id).then((res) => {
          Message.success('操作成功')
          this.getDetail()
        })
      })
    },
  },
}
</script>

<style lang="less" scoped>
.detail-box {
  /deep/ .box-header-subtitle {
    color: #f5222d;
  }
}

.btn-cancel {
  padding: 0;
  height: 32px;
  width: 120px;
  color: #00a4ff;
  border-color: #00a4ff;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  .btn {
    width: 120px;
    height: 32px;
    padding: 0;
    border-radius: 4px;
  }
}
</style>
